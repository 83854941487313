// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "23.1.0",
  "buildNumber": "16079",
  "gitSha": "21e78c998e1eb35d8d489c1d3e3e9813dc18233a",
  "fullVersion": "23.1.0-16079",
  "formattedVersion": "23.1.0 (16079-21e78c998e1eb35d8d489c1d3e3e9813dc18233a)",
  "copyrightYear": "2023"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/23.1.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/23.1.0/${stripLeadingPrefix(suffix, '#')}`
}
    
